import { configureStore } from '@reduxjs/toolkit'
import jobsReducer from './jobSlice'
import eventsReducer from './eventsSlice'
// ...

const store = configureStore({
  reducer: {
    mejobs: jobsReducer,
    events: eventsReducer,
    //users: usersReducer,
  },
})

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch