import { IMeJob } from './mejob';

interface JobDetailProps {
  job: IMeJob;
}

function JobDetails({job}: JobDetailProps) {

    const healthstatus = Object.entries(job.HealthStatus).map(([key, value]) => (
      <div className="flex-container-row" key={key}>
        <div className="label-div">
          <b>{key}:</b>                                                          
        </div>
        <div className="text-div" >
            {JSON.stringify(value)}
        </div>
    
      </div>  
    ));
    return (
        (job?.JobName === undefined) ? 
        <div>Please select</div> : 
      <div className="job-detail-container">

        <div className='flex-container-column'>
          <div className="flex-container-row">
              <div className="label-div">
                <b>Job Name:</b>                                                          
              </div>
              <div className="text-div" >
                  {job.JobName}
              </div>
            
          </div>        
          <div className="flex-container-row">
              <div className="label-div">
                <b>Job Description:</b>                                                          
              </div>
              <div className="text-div" >
                  {job.JobDescription}
              </div>
            
          </div>
          <div className="flex-container-row">
              <div className="label-div">
                <b>Host Name:</b>                                                          
              </div>
              <div className="text-div" >
                  {job.HostName}
              </div>
            
          </div>
          <div className="flex-container-row">
              <div className="label-div">
                <b>Host IP:</b>                                                          
              </div>
              <div className="text-div" >
                  {job.HostIpAddress}
              </div>
            
          </div>  
          <div className="flex-container-row">
              <div className="label-div">
                <b>Job Runs:</b>                                                          
              </div>
              <div className="text-div" >
                  {job.JobRunsSinceStartup}
              </div>
            
          </div>  
          <div className="flex-container-row">
              <div className="label-div">
                <b>Failed Attempts:</b>                                                          
              </div>
              <div className="text-div" >
                  {job.FailedAttemptsSinceStartup}
              </div>
            
          </div>  
          <div className="flex-container-row">
              <div className="label-div">
                <b>Health Status:</b>                                                          
              </div>
              <div className="text-div" >
                  {healthstatus}
              </div>
            
          </div>          
        
        </div>

      </div>

    );
};

export default JobDetails;