/**
 * @description Returns the current window's base url.
 * @example https://aoc-test-frontdoor.azurefd.net, or https://localhost:3000
 * @return {string} - the base url
 */
 export const getBaseUrl = () => {
    let uri = `${window.location.protocol}//${window.location.hostname}`;
    if (uri.includes("localhost")) {
      uri = `${uri}:${window.location.port}`;
    }
    return uri;
  };

/**
 * @description Get the error message from an API Error.  Checks Json "errorMessage" property before defaulting to the standard Error.message property.
 * @param {Error} error - The Error object. Expected to be either ApiStatusResult or Error object definition.
 * @returns {string} - the error message from the object.
 */
 export const getApiErrorMessage = (error: any) => {
    // ApiStatusResult object.
    var errorMessage = error.response?.data?.statusMessage;
  
    // Standard Javascript Exception/Error object.
    if (errorMessage == null || typeof errorMessage === "undefined")
      errorMessage = error.message;
  
    // Obsolete: ApiErrorResult object.
    // Left here for backward compatibility.
    if (errorMessage == null || typeof errorMessage === "undefined")
      errorMessage = error.response?.data?.errorMessage;
  
    // default message.
    if (errorMessage == null || typeof errorMessage === "undefined")
      errorMessage = "Unknown error message.";
  
    return errorMessage;
  };

  /**
 * @description Invokes Application Insights TrackTrace call using a common interface.
 * @param {string} errorMessage - A descriptive error message.
 * @param {*} customProperties - Optional. Object with string key/value pairs for custom properties to attach to the track trace. Defaults to null.
 */
export const trackApiError = (errorMessage:string, customProperties = null) => {
    // Note:
    // SeverityLevels: Verbose = 0, Information = 1, Warning = 2, Error = 3, Critical = 4
  
    // Log the error message from the API.
    // appInsightsClient.trackTrace(
    //   {
    //     message: errorMessage,
    //     severityLevel: 3,
    //   },
    //   customProperties
    // );
  };

  /**
 * Checks that a given parameter string has the parameter 'code'
 * @param {string} paramString - the query parameter string, i.e. '?code=mockCode'
 * @return {bool} - true if the code parameter is included
 */
export const hasCodeInUrl = (paramsString: string) => {
    const searchParams = new URLSearchParams(paramsString);
    return searchParams.has("code");
  };