import JobCard from './JobCard';
import { IMeJob, MeJob } from './mejob';

interface JobListProps{
    jobs: IMeJob[];
    onSelect: (job: IMeJob) => void;
}


function JobList({jobs, onSelect}: JobListProps) {

    const handleOnClick = (selectedJob: IMeJob) => {
        onSelect(selectedJob);
    };
    
    const items = jobs.map(mejob => (
        <div key={mejob.InstanceId}  onClick={() => handleOnClick(mejob)}>
            <JobCard job={mejob}/>
        </div>
    ));
    return(<div className="row" >{items}</div>);
}

export default JobList;