import * as signalR from "@microsoft/signalr";
import appSettings from "../../appsettings";

export default class SignalRService {

    private connection: signalR.HubConnection;
    public events: (onMessageReceived: (username: string, message: string) => void) => void;
    public clearHandlers = () => {
        this.connection.off("messageReceived");
    }
    constructor() {
        console.info("signalRService constructor");

        this.connection = new signalR.HubConnectionBuilder()
            //.withUrl("https://mejobsapi-test-frontdoor.azurefd.net/api/mejobevents", { 
            //.withUrl("https://mejobsapi-test-site-westus2.azurewebsites.net/api/mejobevents", { 
            //.withUrl("https://localhost:44362/api/mejobevents", { 
            .withUrl("/api/mejobevents", { 
                accessTokenFactory: () => this.getAccessToken(),
            withCredentials: false, // to AllowAllOrigin should be false
            skipNegotiation: false, //must be false for AzureSignalR
            transport: signalR.HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .build();


        this.initialize();
 
        this.events = (onMessageReceived) => {
        this.connection.on("messageReceived", (source, message) => {
            onMessageReceived(source, message); });
        };
    }
    
    initialize = async () =>{
        try {
            await this.connection.start()
            console.info('SignalR connected');

        } catch (err) {
            console.error('SignalR connection error: ', err);
        }

       
    }

    getAccessToken = () => {
        let str = sessionStorage.getItem(`oidc.user:${appSettings.OIDC_PROVIDER}:${appSettings.OIDC_CLIENT_ID}`);
        if ( str === null)
          return undefined;
        else 
        {
          const oidcStorage = JSON.parse(str)
          return oidcStorage.access_token
        }
     
    }

}