import React, { useContext, useEffect, useState} from "react";
type Mode = "light" | "dark" | "system";
export type ThemeContextType = {
    mode: string;
    setMode: (mode: Mode) => void;
}

export const ThemeContext = React.createContext<ThemeContextType>({
    mode: "light",
    setMode: () => {}  
});

export const ThemeConsumer = ThemeContext.Consumer;
interface ThemeProviderProps {
    children: React.ReactNode
}

export default function ThemeProvider(props: ThemeProviderProps) {
    const [lightMode, setLightMode] = useState<Mode>("light");

    useEffect(() =>{

        var theme = document.getElementById('theme');
        switch (lightMode)
        {
            case "light":
                theme?.setAttribute('href', 'https://cdn.rawgit.com/Chalarangelo/mini.css/v3.0.1/dist/mini-default.min.css')
                break;
            case "dark":
                theme?.setAttribute('href', 'https://cdn.rawgit.com/Chalarangelo/mini.css/v3.0.1/dist/mini-dark.min.css')
                break;
        }


    }, [lightMode]);
    return <ThemeContext.Provider value={{ 
        mode: lightMode,
        setMode: setLightMode,
    }}>{props.children}</ThemeContext.Provider>;
}
// export class ThemeProvider extends Component<ThemeProviderProps> {
//     constructor(props: ThemeProviderProps) {
//         console.info("ThemeProvider constructor");
//         super(props);
//     }

//    render() {
//         return <ThemeContext.Provider value={{ 
//             mode: lightMode,
//             setMode: setLightMode,
//         }}>{this.props.children}</ThemeContext.Provider>;
//     }
// }

export const useTheme = () => {
    console.info("useContext useTheme called");
    const context = useContext(ThemeContext);
    if (!context) {
      throw new Error("useTheme must be used within a ThemeProvider. Wrap a parent component in <ThemeProvider> to fix this error.")
    }
    return context;
  }