import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface EventsState{
    events: string[]
};

const initialState: EventsState = {
    events: []
};

export const eventsSlice = createSlice({
    name: 'jobevents',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<string>) => {
            state.events.push(action.payload);
        },
        clear: state => {
            state.events = [];
        }
    }
});

export const { add: addEvent, clear: clearEvents } = eventsSlice.actions;

export const selectJobEvents = (state: RootState) => state.events.events;

export default eventsSlice.reducer;