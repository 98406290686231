import appSettings from "../../appsettings";

function translateStatusToErrorMessage(status: number) {
    switch (status) {
      case 401:
        return 'Please login again.';
      case 403:
        return 'You do not have permission to view the project(s).';
      default:
        return 'There was an error retrieving the project(s). Please try again.';
    }
  }
function checkStatus(response: any) {
    console.debug("checkStatus")
    if (response.ok) {
      return response;
    } else {
      const httpErrorInfo = {
        status: response.status,
        statusText: response.statusText,
        url: response.url,
      };
      console.log(`log server http error: ${JSON.stringify(httpErrorInfo)}`);
  
      let errorMessage = translateStatusToErrorMessage(httpErrorInfo.status);
      throw new Error(errorMessage);
    }
  }

  function parseJSON(response: Response) {
    console.debug("parseJSON")
    return response.json();
  }
  function sortJobModels(data: any[]): any[] {
    console.debug("sortJobModels")
    // sort first and than convert t
    let jobs: any[] = data.sort((a, b) => a.eventData.JobName > b.eventData.JobName ? 1 : -1);
    return jobs;
  }

  // function convertToJobModels(data: any[]): MeJob[] {
  //   // sort first and than convert t
  //   let jobs: MeJob[] = data.sort((a, b) => a.eventData.JobName > b.eventData.JobName ? 1 : -1)
  //                           .map(convertToJobModel)
  //   return jobs;
  // }

  // function convertToJobModel(item: any): MeJob {
  //   return new MeJob(item);
  // }
  const getAccessToken = () => {
    let str = sessionStorage.getItem(`oidc.user:${appSettings.OIDC_PROVIDER}:${appSettings.OIDC_CLIENT_ID}`);
    if ( str === null)
      return undefined;
    else 
    {
      const oidcStorage = JSON.parse(str)
      return oidcStorage.access_token
    }
 
  }
  
  const jobsApi = {

    get () {
        return fetch('/api/mejobs/jobs',{
            method: 'GET',
            headers: {
                'Authorization': 'bearer ' + getAccessToken(),
            }
        })
        .then(checkStatus)
        .then(parseJSON)
        .then(sortJobModels)
        .catch((error: TypeError) =>  {
            console.log('log client error ' + error);
            throw new Error(
                'There was an error retrieving the jobs. Please try again.'
            );
        });
    },
    put () {

    }
};

export {jobsApi}