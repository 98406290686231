import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { useSignalR } from './providers/SignalRProvider';
import { AppRoutes } from './routes/routes';
import { addEvent } from './state/eventsSlice';
import { setJobs, updateJob } from './state/jobSlice';
import { useAppDispatch } from './state/hooks';
import { jobsApi } from './components/jobs/jobsApi';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, fas } from '@fortawesome/free-solid-svg-icons'


library.add( fas, faCheckSquare, faCoffee)

function App() {

  const { events, clearHandlers } = useSignalR();
  const [refresh, setRefresh] = useState<number>(0);
  const [lastHbTime, setLastActivity] = useState<Date>(new Date());
  const dispatch = useAppDispatch();  


  useEffect(() => {
    console.info("checkInterval useEffect mounted")    

    const interval = setInterval(() => {
      const now = new Date();
      const diffMs = Math.abs(now.getTime() - lastHbTime.getTime());
      if (diffMs > (1000*60*15))
      {
        console.info("no activity detetcted for > 15 minutes. manually refreshing")
        setRefresh(refresh + 1);        
      }

    }, 60000);
    return () => { 
      clearInterval(interval);
      console.info("checkInterval useEffect unmounted");
    }
  });

  useEffect(() => {
    console.info("getJobs useEffect mounted")    
    async function getApiJobs() {
      try{
        const data = await jobsApi.get();
        dispatch(setJobs(data))
      }
      catch (e){
        console.log(e);
      }
    }
    getApiJobs();
    return () => { 
      console.info("getJobs useEffect unmounted");
    }
  }, [refresh]);  

  useEffect(() => {
    console.info("useEffect SignalR mounted");
    const handleMessageReceived = (source: string, message: string) => {
      setLastActivity(new Date());
      console.info("handleMessageReceived:" + source + ":" + message);
      const appevt = JSON.parse(message);
      dispatch(updateJob(appevt));
      dispatch(addEvent(appevt.eventTimeStampUTC + ":" + appevt.eventType + " received from " + source));
   }
    events(handleMessageReceived);

    return () => { 
      console.info("useEffect SignalR  unmounted");
      clearHandlers();
    }
  });

  return (
    <BrowserRouter children={AppRoutes} basename={"/"} />        
  );
}

export default App;


