import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IMeJob } from "./mejob";
import './style.css';

const jobCardStyle = {
    div: {
      height: "25vh",
      overflow: "auto",
      margin: ".2rem"
      
    },
    div2: {
      position:"absolute",
      bottom: "0%"
    },
    body: {
        width: "36rem"
    }
  };

function formatDescription(description: string | undefined): string {
    if (description === undefined) 
        return ''.repeat(123);
    else
        if (description.length > 120)
            return description.substring(0, 120) + '...';
        else if (description.length < 120)
            return description + ''.repeat(120 - description.length);
        else
            return description;
        //return description.length > 180 ?  description.substring(0, 120) + '...' : description + ''.repeat(123- description.length);
  }
  
interface JobCardProps {
    job: IMeJob;
}

function JobCard(props: JobCardProps) {
    const {job} = props;
    return (
        <div className="w3-panel w3-card w3-hover-shadow flex-container" style={jobCardStyle.div} >
            <div className="card-body" >
                <h5 className="strong" style={{textAlign: 'justify'}}>
                    <strong>{job.JobName}</strong>
                </h5>
                <div className="tooltip">
                    <p style={{textAlign: 'justify'}}>{formatDescription(job.JobDescription)} </p>
                    <span className="tooltiptext">some tooltip</span>
                </div>
                <div className="flex-container-row" style={{marginTop: '3rem'}}>
                    <div className="flex-item-left-2">
                        <div className="flex-container-column">
                            <div className="flex-container-row">
                                <div className="icon-div">
                                    <FontAwesomeIcon icon="clock"></FontAwesomeIcon>                                         
                                </div>
                                <div className="label-div" >
                                    <b>Service Startup Time:</b>
                                </div>
                                <div className="text-div">
                                    {job.JobStartupTime === undefined ? 'undefined' : new Date(job.JobStartupTime).toLocaleString(navigator.language, {
                                        month: 'numeric',
                                        day: 'numeric',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}                                    
                                </div>                                
                            </div>
                            <div className="flex-container-row">
                                <div className="icon-div" >
                                   <FontAwesomeIcon icon="clock"></FontAwesomeIcon>                                        
                                </div>
                                <div className="label-div">
                                    <b>Last Execution Time:</b>
                                </div>
                                <div className="text-div">
                                    {job.LastExecutionTime === undefined ? 'undefined' : new Date(job.LastExecutionTime).toLocaleString(navigator.language, {
                                        month: 'numeric',
                                        day: 'numeric',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                     })}                                    
                                </div>
                            </div>
                            <div className="flex-container-row">
                            <div className="icon-div" >
                                   <FontAwesomeIcon icon="clock"></FontAwesomeIcon>                                        
                                </div>
                                <div className="label-div">
                                    <b>Next Execution Time:</b>
                                </div>
                                <div className="text-div">
                                    {job.NextExecutionTime === undefined ? 'undefined' : new Date(job.NextExecutionTime).toLocaleString(navigator.language, {
                                        month: 'numeric',
                                        day: 'numeric',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}                                    
                                </div>                                
                            </div>

                        </div>


                    </div>
                  
                    <div className="flex-item-right-2">
                        <div className="flex-container-column"  style={{marginTop: '.25rem'}}>
                            <div className="flex-container-row">
                                <div className="icon-div"> 
                                {
                                    (job.JobEnabled  
                                    ?
                                        <div className="tooltip">
                                            <FontAwesomeIcon icon="power-off" color="green" size="2x"></FontAwesomeIcon>
                                            <span className="tooltiptext">Status</span>
                                        </div>
                                        
                                    : 
                                        <div className="tooltip">
                                            <FontAwesomeIcon icon="power-off" size="2x"></FontAwesomeIcon>
                                            <span className="tooltiptext">Job Enabled and Running</span>
                                        </div>
                                    )

                                }


                                </div>                                
                                <div className="text-div">
                                    {(job.JobEnabled ? 'Job Enabled' : 'Job Disabled')}
                                </div>
                            </div>

                            <div className="flex-container-row">
                                <div className="tooltip">
                                    <FontAwesomeIcon icon="heartbeat" size="2x"></FontAwesomeIcon>
                                    <span className="tooltiptext">Last Known Activity</span>                                         
                                </div>
                                <div className="text-div" >
                                    <strong>@</strong>{job.TimeStampUtc === undefined ? 'undefined' : new Date(job.TimeStampUtc).toLocaleTimeString(navigator.language, {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}  
                                </div>
                              
                            </div>                            
                        </div>
                    
                    </div>
                </div>                
            </div>
        </div>
    );
}

export default JobCard;