export interface IMeJob {
    EventSource?: string;
    JobName?: string;
    JobDescription?: string;
    InstanceId?: string;
    TimeStampUtc?: string;
    LastExecutionTime?: string;
    NextExecutionTime?: string;
    JobStartupTime?:  string;
    JobRunsSinceStartup?: number;
    FailedAttemptsSinceStartup?: number;
    JobEnabled?: boolean;
    IsJobExecuting?: boolean;
    HeartbeatIntervalMs?: number;
    HostName?: string;
    HostIpAddress?: string;
    HealthStatus?: any;
}
export class MeJob implements IMeJob{
    EventSource: string | undefined;
    JobName: string | undefined;
    JobDescription: string | undefined;
    InstanceId: string | undefined;
    TimeStampUtc: string | undefined;
    LastExecutionTime: string | undefined;
    NextExecutionTime: string | undefined;
    JobStartupTime:  string | undefined;
    JobRunsSinceStartup: number | undefined;
    FailedAttemptsSinceStartup: number | undefined;
    JobEnabled: boolean | undefined;
    IsJobExecuting: boolean | undefined;
    HeartbeatIntervalMs: number | undefined;
    HostName: string | undefined;
    HostIpAddress: string | undefined;
    HealthStatus: any = {};

    constructor(initializer?: any)
    {
        if (!initializer) return;
        this.EventSource = initializer.eventSource !== undefined ? initializer.eventSource : '';
        this.JobName = initializer.eventData.JobName !== undefined ? initializer.eventData.JobName :  '';
        this.JobDescription = initializer.eventData.JobDescription !== undefined ? initializer.eventData.JobDescription :  '';
        this.InstanceId = initializer.eventData.InstanceId !== undefined ? initializer.eventData.InstanceId :  '';
        this.JobRunsSinceStartup = initializer.eventData.JobRunsSinceStartup !== undefined ? initializer.eventData.JobRunsSinceStartup :  -1;
        this.FailedAttemptsSinceStartup = initializer.eventData.FailedAttemptsSinceStartup !== undefined ? initializer.eventData.FailedAttemptsSinceStartup : -1;
        this.JobStartupTime = initializer.eventData.JobStartupTime !== undefined ? initializer.eventData.JobStartupTime : '';
        this.TimeStampUtc = initializer.eventData.TimeStampUtc !== undefined ? initializer.eventData.TimeStampUtc : '';
        this.LastExecutionTime = initializer.eventData.LastExecutionTime !== undefined ? initializer.eventData.LastExecutionTime : '';
        this.NextExecutionTime = initializer.eventData.NextExecutionTime !== undefined ? initializer.eventData.NextExecutionTime : '';
        this.JobEnabled = initializer.eventData.JobEnabled !== undefined ? initializer.eventData.JobEnabled : false;
        this.IsJobExecuting = initializer.eventData.IsJobExecuting !== undefined ? initializer.eventData.IsJobExecuting : false;       
        this.HeartbeatIntervalMs = initializer.eventData.HeartbeatIntervalMs !== undefined ? initializer.eventData.HeartbeatIntervalMs : -1;       
        this.HostName = initializer.eventData.HostName !== undefined ? initializer.eventData.HostName : '';       
        this.HostIpAddress = initializer.eventData.HostIpAddress !== undefined ? initializer.eventData.HostIpAddress : '';       
        this.HealthStatus = initializer.eventData.HealthStatus !== undefined ? initializer.eventData.HealthStatus : {};    
        //this.JobStartupTime = initializer.eventData.HealthStatus.ServiceStartupTime !== undefined ? initializer.eventData.HealthStatus.ServiceStartupTime : '';
    
    }
}

// export class HealthStatus{
//     ServiceStartupTime: Date = new Date();
//     constructor(initializer?: any)
//     {
//         if(!initializer) return;
//         if (initializer.ServiceStartupTime) this.ServiceStartupTime = new Date(initializer.ServiceStartupTime);
//     }
   
// }