const REFRESH_INTERVAL = 60000;

const appSettings = {
    OIDC_PROVIDER: 'https://fedauth.qa.alaskasworld.com',
    OIDC_CLIENT_ID: 'FjddRoAnAbws7j9vje',
    OIDC_REDIRECT_ROUTE: '/signin-oidc',
    OIDC_END_SESSION_ENDPOINT: 'config.OIDC_END_SESSION_ENDPOINT',
    APPINSIGHTS_INSTRUMENTATIONKEY: 'config.APPINSIGHTS_INSTRUMENTATIONKEY',
    REFRESH_INTERVAL: parseInt((isNaN(REFRESH_INTERVAL) ? "60000" : 'REFRESH_INTERVAL'), 10),
    TEAM_MESSAGING_BASE_URL: 'config.TEAM_MESSAGING_BASE_URL',
    SPLIT_FEATURE_FLAGGING_KEY: 'config.SPLIT_FEATURE_FLAGGING_KEY',   
    INACTIVITY_TIMEOUT:{ WARNING_TIMEOUT: 7200000, COUNTDOWN_TIMEOUT: 300000 }, // PROVIDES TIMES IN MILLI-SECONDS e.g 1000 FOR 1 SECOND,
    API_BASE_URL: '',
    REACT_APP_SILENT_REDIRECT_URL: '/silentrenew',
};

export default appSettings;