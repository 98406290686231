import React, {Component, useContext} from "react";
import SignalRService from "../services/signalRService/signalRService";

export type SignalRContextType = {
    events: any,
    clearHandlers: any,
}

export const SignalRContext = React.createContext<SignalRContextType>({
    events: () => ({}),
    clearHandlers: () => ({}),
});

export const SignalRConsumer = SignalRContext.Consumer;
interface SignalRProviderProps {
    children: React.ReactNode
}
export class SignalRProvider extends Component<SignalRProviderProps> {
    signalRService;
    constructor(props: SignalRProviderProps) {
        console.info("SignRProvider constructor");
        super(props);
        this.signalRService = new SignalRService();
    }

    public events = () => this.signalRService.events;
    render() {
        return <SignalRContext.Provider value={{ 
            events: this.signalRService.events,
            clearHandlers: this.signalRService.clearHandlers,
        }}>{this.props.children}</SignalRContext.Provider>;
    }
}

export const useSignalR = () => {
    console.info("useContext useSignalR called");
    const context = useContext(SignalRContext);
    if (!context) {
      throw new Error("useSignalR must be used within a SignalRProvider. Wrap a parent component in <SignalRProvider> to fix this error.")
    }
    return context;
  }