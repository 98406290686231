import type { RootState} from './store'
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MeJob } from '../components/jobs/mejob';

interface JobState{
    jobs: any[],
};

const initialState: JobState = {
    jobs: [],
};

export const jobSlice = createSlice({
    name: 'mejobs',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<any>) => {

            const newjob = new MeJob(action.payload);
            switch (action.payload.eventType)
            {
                case "HeartbeatEvt":
                    state.jobs = state.jobs.map(x => {
                        if (newjob.EventSource === x.EventSource) { 
                            return JSON.parse(JSON.stringify(newjob)); }
                        else { return x; } 
                        });
                    break;
                case "JobRunExecutedEvt":
                case "JobRunCompletedEvt":
                case "JobExceptionEvt":
                break;
                case "JobStartupEvt":
                    state.jobs.push(JSON.parse(JSON.stringify(newjob)));
                    break;      
                case "JobShutdownEvt":
                    state.jobs = state.jobs.filter(x => action.payload.EventSource !== x.EventSource);
                    break;      
            }                
        },
        set: (state, action: PayloadAction<any[]>) => {
            state.jobs = action.payload.map( x => {
                const newjob = new MeJob(x);
                return JSON.parse(JSON.stringify(newjob));                
            });
        },
    }
});

export const { update: updateJob, set: setJobs } = jobSlice.actions;

export const selectJobs = (state: RootState) => state.mejobs.jobs;

export default jobSlice.reducer;