import HomePage from "./pages/homePage/HomePage";
import { AuthConsumer } from "../providers/AuthProvider";

interface PrivateRouteProps {
    component: any
    path: any,
}
function PingAuthRoute( {component, ...path}: PrivateRouteProps) {
    return (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect }) => {
                if (isAuthenticated()) {
                    //return <HomePage/>;
                    return <HomePage/>;
                } else {
                    signinRedirect();
                    return <span>loading</span>;
                }
            }}
    </AuthConsumer>
    );
};

export default PingAuthRoute;