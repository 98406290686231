import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  SyntheticEvent, useEffect, useRef, useState } from 'react';
import JobList from '../../../components/jobs/JobList';
import { IMeJob, MeJob } from '../../../components/jobs/mejob';
import { MOCK_JOBS } from '../../../components/jobs/MOCK_JOBS';
import './homePage.css';
import JobDetails from '../../../components/jobs/JobDetails';
import JobDiagram from '../../../components/jobs/JobDiagram';
import JobThreeDiagram from '../../../components/jobs/JobThreeDiagram';
import JobEventsPanel from '../../../components/jobs/JobEventsPanel';

import { useAppDispatch, useAppSelector} from '../../../state/hooks';
import { selectJobEvents } from '../../../state/eventsSlice';
import { selectJobs } from '../../../state/jobSlice';

function HomePage() {
  const [jobSelected, setJobSelected] = useState<IMeJob>(new MeJob());
  const [filteredJobs, setFilteredJobs] = useState<IMeJob[]>(MOCK_JOBS);
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const jobEvents = useAppSelector(selectJobEvents);
  const mejobs = useAppSelector(selectJobs);
  const dispatch = useAppDispatch();

  const clearSearch = (event: SyntheticEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (searchRef.current !== null)
    {
      searchRef.current.value = '';      
      setSearchTerm('');
    }


  };
  const searchJobs = (event: any) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (searchRef.current !== null)
    {
      setSearchTerm(searchRef.current.value);

    }
  };
  const handleJobSelected = (job: IMeJob) => { 
    setJobSelected(job);
    console.log(job.JobName + ' selected');
  };

  const myInlineStyle = {
    div: {
      display: "block",
      height: "100%",
      overflow: "auto"
    },
    search: {
      position: 'fixed',
    }

  };

  useEffect(() => {
    console.info("useEffect setfiltered jobs");
    if (searchTerm === '')
    {
      setFilteredJobs(mejobs);
    }
    else
    {
      const data = mejobs.filter( (item: { JobDescription: string; JobName: string; }) => {
        return item.JobDescription?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        item.JobName?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())

        //return Object.values(item).join('').toLowerCase().includes(searchTerm.toLowerCase())
        });
      setFilteredJobs(data);
      }
  }, [dispatch, searchTerm, mejobs]);

  return (
    <>
      <div className="grid-container" >
        <div className="jobs" >
          <div className="border-top border-bottom border-left border-right " id="boxshadow" style={ myInlineStyle.div }>
            <div className="search-container" >
              <form  id="boxshadow">
                <div className='input-container'>
                  <input ref={searchRef} className="input-field" type="text" placeholder="Search..." name="search" onChange={searchJobs}></input>
                  <button type="submit" onClick={clearSearch}><FontAwesomeIcon icon="search"></FontAwesomeIcon></button>                
                </div>

              </form>
            </div>
            <div >
              <JobList jobs={filteredJobs} onSelect={handleJobSelected}></JobList>                
            </div>            
          </div>

        </div>
        <div className="healthstatus">
          <div className="border-top border-bottom border-left border-right " id="boxshadow" style={ myInlineStyle.div }>
            <JobDetails job={jobSelected} ></JobDetails>
            {/* <JobDiagram job={jobSelected} fullscreen={false}  autostart={false}></JobDiagram> */}
            {/* <JobThreeDiagram job={jobSelected} /> */}
          </div>
        </div>
        <div className="events">
          <div className="border-top border-bottom border-left border-right " id="boxshadow" style={ myInlineStyle.div }>
            <JobEventsPanel jobEvents={jobEvents}></JobEventsPanel>
          </div>
        </div>
      </div>    
    </>

  );
}

export default HomePage;