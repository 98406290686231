import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import JobEventsPanel from "../../../components/jobs/JobEventsPanel";
import { selectJobEvents } from "../../../state/eventsSlice";
import { useAppSelector } from "../../../state/hooks";
import './gpmPage.css';

function GPMPage() {
  const myInlineStyle = {
    div: {
      display: "block",
      height: "100%",
      overflow: "auto"
    },
    ribbon: {
      display: "block",
      width: "100",
      overflow: "auto"
    },
    progress: {
      display: "block",
      width: "100%",     
      marginTop: "1rem" 
    }

  };
  const [percentComplete, setPercentComplete] = useState(32);
  const jobEvents = useAppSelector(selectJobEvents);

  function onChangeValue(event: any) {
    setPercentComplete(event.target.value);
    console.log(event.target.value);
  }
  useEffect(() => {

  });  
  return (
    <>
      <div  className="gpm-container">
        <div className="ribbon" >
          <div  className="border-top border-bottom border-left border-right " id="boxshadow" style={ myInlineStyle.div }>
            <div className="flex-container-column " >

              <div className="gpm-flex-container-row "  style={ myInlineStyle.ribbon }>

                <div className="card-body" >
                  <h5 className="strong" style={{textAlign: 'justify'}}>
                      <strong>Some Job Name</strong>
                  </h5>
                  <div>
                      <p style={{textAlign: 'justify'}}>a;ladkj a;kldfj asldkfj a;sldkfja;sldkfj a;lksdjf;alskdf ja;lskd ja;slkdfj a;lksdfj ;alskdfja;lsdkfj </p>
                  </div>
                  <div className="gpm-flex-container-row" style={{marginTop: '2rem'}}>
                      <div className="flex-item-left-2">
                          <div className="gpm-flex-container-column">
                              <div className="gpm-flex-container-row">
                                  <div className="icon-div">
                                      <FontAwesomeIcon icon="clock"></FontAwesomeIcon>                                         
                                  </div>
                                  <div className="label-div" >
                                      <b>Service Startup Time:</b>
                                  </div>
                                  <div className="text-div">
                                      {(new Date()).toLocaleString(navigator.language, {
                                          month: 'numeric',
                                          day: 'numeric',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit'
                                      })}                                    
                                  </div>                                
                              </div>
                              <div className="gpm-flex-container-row">
                                  <div className="icon-div" >
                                    <FontAwesomeIcon icon="clock"></FontAwesomeIcon>                                        
                                  </div>
                                  <div className="label-div">
                                      <b>Last Execution Time:</b>
                                  </div>
                                  <div className="text-div">
                                      {(new Date()).toLocaleString(navigator.language, {
                                          month: 'numeric',
                                          day: 'numeric',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit'
                                      })}                                    
                                  </div>
                              </div>
                              <div className="gpm-flex-container-row">
                              <div className="icon-div" >
                                    <FontAwesomeIcon icon="clock"></FontAwesomeIcon>                                        
                                  </div>
                                  <div className="label-div">
                                      <b>Next Execution Time:</b>
                                  </div>
                                  <div className="text-div">
                                      {(new Date()).toLocaleString(navigator.language, {
                                          month: 'numeric',
                                          day: 'numeric',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit'
                                      })}                                    
                                  </div>                                
                              </div>

                          </div>


                      </div>
                      <div>
                        <button type="button">Run</button>
                        <button type="button">Cancel</button>
                      </div>                    
                      {/* <div className="gpm-flex-item-right-2">
                          <div className="gpm-flex-container-column" >
                              <div className="gpm-flex-container-row">
                                  <div className="icon-div"> 
                                  {
                                      (true  
                                      ?
                                          <div>
                                              <FontAwesomeIcon icon="power-off" color="green" size="2x"></FontAwesomeIcon>
                                          </div>
                                          
                                      : 
                                          <div>
                                              <FontAwesomeIcon icon="power-off" size="2x"></FontAwesomeIcon>
                                          </div>
                                      )

                                  }


                                  </div>                                
                                  <div className="text-div">
                                      {(true ? 'Job Enabled' : 'Job Disabled')}
                                  </div>
                              </div>

                              <div className="gpm-flex-container-row">
                                  <div>
                                      <FontAwesomeIcon icon="heartbeat" size="2x"></FontAwesomeIcon>
                                  </div>
                                  <div className="text-div" >
                                      <strong>@</strong>{(new Date()).toLocaleTimeString(navigator.language, {
                                          hour: '2-digit',
                                          minute: '2-digit'
                                      })}  
                                  </div>
                                
                              </div>                            
                          </div>
                      
                      </div> */}
                  </div>                
                </div>

              </div>

              <div className="gpm-flex-container-row "  style={ myInlineStyle.progress }>                   
                <progress id="file" value={percentComplete} max="100"> {percentComplete}% </progress>
              </div>                  

            </div>

          </div>

        </div>
        <div className="console" >
          <div className="border-top border-bottom border-left border-right " id="boxshadow" style={ myInlineStyle.div }>
            <JobEventsPanel jobEvents={jobEvents}></JobEventsPanel>
          </div>

        </div>

      </div>              

    </>

  )
}

export default GPMPage;