import { MeJob } from "./mejob";

export const MOCK_JOBS =
[
  new MeJob(  {
    "eventSource": "ME_GEFORLOOP_DATAFEED:001e3e46-6161-423b-8c57-6d6af345beaa:me-mejobscheduler-deployment-5c4d9c4b4f-d9k6p",
    "eventType": "HeartbeatEvt",
    "eventId": "51de27bd-6229-40b7-a7f0-479c9c86fa70",
    "eventTimeStampUTC": "2022-12-07T16:29:17.0779661Z",
    "eventData": {
      "JobName": "ME_GEFORLOOP_DATAFEED",
      "JobDescription": "ME_GEFORLOOP_DATAFEED is a scheduled job that runs once a day and retrieves AS & VX TRAX Aircraft Engine information and uploads the information in CSV format to an SFTP site provided by partner vendor. The Daily Job execution time can be modified by updating the appsettings.json file or overriding the Azure KeyVault secret in the associated Environment. Dependencies include connections to AS & VX Trax and the vendor provided SFTP site.",
      "InstanceId": "001e3e46-6161-423b-8c57-6d6af345beaa",
      "JobStartupTime": "2022-12-06T20:53:15.5654207Z",
      "TimeStampUtc": "2022-12-07T16:29:17.0779661Z",
      "LastExecutionTime": "2022-12-07T14:00:00.0078042Z",
      "NextExecutionTime": "2022-12-08T14:00:00Z",
      "ScheduledExecutionTime": "",
      "JobEnabled": true,
      "IsJobExecuting": false,
      "JobRunsSinceStartup": 1,
      "FailedAttemptsSinceStartup": 0,
      "HeartbeatIntervalMs": 180000,
      "ExecutionIntervalSec": 0,
      "HostName": "me-mejobscheduler-deployment-5c4d9c4b4f-d9k6p",
      "HostIpAddress": "10.128.4.197",
      "HealthStatus": {
        "SftpServer": "adc-sftp-staging.geaviation.com:22",
        "SftpConnectionStatus": "Successful",
        "VxTraxServer": "HostName=sc9testraxdb01;Service=VXTXTST",
        "VxTraxConnectionStatus": "Successful",
        "AxTraxServer": "HostName=seatsatx19ora01;Service=TXASTEST",
        "AxTraxConnectionStatus": "Successful",
        "NextExecutionTime": "2022-12-08T14:00:00Z",
        "LastExecutionTime": "2022-12-07T14:00:00Z",
        "IsJobExecuting": false,
        "JobEnabled": true,
        "HeartbeatIntervalMs": 180000,
        "Inputs": [
          {
            "Name": "Data Source=(DESCRIPTION=(ADDRESS=(PROTOCOL=TCP)(HOST=sc9testraxdb01.virginam.com)(PORT=1521))(CONNECT_DATA=(SERVICE_NAME=vxtxtst)));User Id=INTF;Password=intf123;Validate Connection=true;Connection Timeout=60",
            "EndpointType": 2
          },
          {
            "Name": "Data Source=(DESCRIPTION=(ADDRESS=(PROTOCOL=TCP)(HOST=astrax.test.db.insideaag.com)(PORT=1522))(CONNECT_DATA=(SERVICE_NAME=TXASTEST)));User Id=INTF;Password=INTF9;Validate Connection=true;Connection Timeout=60",
            "EndpointType": 2
          }
        ],
        "Outputs": [
          {
            "Name": "adc-sftp-staging.geaviation.com",
            "EndpointType": 8
          }
        ],
        "Dependencies": [
          {
            "Name": "https://me-emailserviceapi.test.alaskaair.com/email",
            "EndpointType": 9
          },
          {
            "Name": "appeventhub-test",
            "EndpointType": 11
          }
        ]
      }
    }
  }),
  new MeJob({
    "eventSource": "ME_ASCT_BCFINDER:14265f80-3df0-4126-ba80-30a0dc1dbde8:",
    "eventType": "HeartbeatEvt",
    "eventId": "34c8802d-0e10-416f-87c7-c14325550819",
    "eventTimeStampUTC": "2022-12-07T16:30:39.5917186Z",
    "eventData": {
      "JobName": "ME_ASCT_BCFINDER",
      "JobDescription": "ME_ASCT_BCFINDER is a scheduled job that runs once a day and exports all Tail software info as PDF files to an on-prem fileshare as part of a DisasterRecover protocol.  The Daily Job execution time can be modified by updating the appsettings.config file or overriding the Azure KeyVault secret in the associated Environment. Dependencies include connections to the Engineering Effectivity Azure DB and the on-prem FileShare for DR.",
      "InstanceId": "14265f80-3df0-4126-ba80-30a0dc1dbde8",
      "JobStartupTime": "2022-12-06T20:53:15.5633892Z",
      "TimeStampUtc": "2022-12-07T16:30:39.5917186Z",
      "LastExecutionTime": "2022-12-07T13:00:00.0027372Z",
      "NextExecutionTime": "2022-12-08T13:00:00Z",
      "ScheduledExecutionTime": "",
      "JobEnabled": true,
      "IsJobExecuting": false,
      "JobRunsSinceStartup": 0,
      "FailedAttemptsSinceStartup": 0,
      "HeartbeatIntervalMs": 180000,
      "ExecutionIntervalSec": 0,
      "HostName": "me-mejobscheduler-deployment-5c4d9c4b4f-d9k6p",
      "HostIpAddress": "10.128.4.197",
      "HealthStatus": {
        "Validated": true,
        "FileCount": 292,
        "FileSharePath": "TEMP\\Maintenance\\ASCT",
        "SoftwareMgrStatus": "ASCT Database status: True\nEng Eff Database status: True\n",
        "ErrorMessage": "",
        "LastExecutionTime": "2022-12-07T13:00:00.0027372Z",
        "NextExecutionTime": "2022-12-08T13:00:00Z",
        "Inputs": [
          {
            "Name": "Server=tcp:engeffectivity-westus2-test-sqlsvr-1.database.windows.net,1433;Initial Catalog=engeffectivity-test-sqldb-1;Persist Security Info=False;User ID=engeffectivityAdmin;Password=y/iZj/GF.[oAKp[!7t0gzr4ryGcIViCb;MultipleActiveResultSets=False;Encrypt=True;TrustServerCertificate=False;Connection Timeout=30;",
            "EndpointType": 4
          },
          {
            "Name": "Server=tcp:asct-test-geo-sql.database.windows.net,1433;Initial Catalog=asct-test-sqldb;Persist Security Info=False;User ID=asctApp;Password=FT5dcJ=Kycd}0Se}MIC-TcZKdW(M3P{G;MultipleActiveResultSets=False;Encrypt=True;TrustServerCertificate=False",
            "EndpointType": 4
          }
        ],
        "Outputs": [
          {
            "Name": "fltopsfs.alaskaair.com/FLTOPSTEST/TEMP\\Maintenance\\ASCT",
            "EndpointType": 0
          }
        ],
        "Dependencies": [
          {
            "Name": "",
            "EndpointType": 9
          },
          {
            "Name": "appeventhub-test",
            "EndpointType": 11
          }
        ]
      }
    }
  })
]

export const MOCK_JOBS2 =
[
  {
    "JobName": "AsXelusDailyMasterDataJob",
    "JobDescription": "AsXelusDailyMasterDataJob is a scheduled job that runs once a day and retrieves Xelus Daily Master Data from AS TRAX..",
    "InstanceId": "6327574f-1555-4bdd-ae3d-ac63260ce837",
    "TimeStampUtc": "2022-10-10T15:26:38.1413114Z",
    "LastExecutionTime": "2022-10-10T00:12:00.0015353Z",
    "NextExecutionTime": "2022-10-11T00:12:00Z",
    "JobEnabled": true,
    "IsJobExecuting": false,
    "HeartbeatIntervalMs": 300000,
    "HostName": "me-xelus-deployment-5f488dfd46-hbsrx",
    "HostIpAddress": "10.128.4.161",
    "HealthStatus": {
      "ServiceStartupTime": "2022-10-08T17:26:30.4294204Z",
      "Validated": false,
      "Files": null,
      "FileCount": 0,
      "FileSharePath": null,
      "SoftwareMgrStatus": null,
      "ErrorMessage": null,
      "NextExecutionTime": "2022-10-11T00:12:00Z",
      "LastExecutionTime": "2022-10-10T00:12:00.0015353Z"
    }
  },
  {
    "JobName": "VxXelusDailyMasterDataJob",
    "JobDescription": "VxXelusDailyMasterDataJob is a scheduled job that runs once a day and retrieves Xelus Daily Master Data from VX TRAX..",
    "InstanceId": "80e3134e-8f54-4556-9180-ba7d5166c142",
    "TimeStampUtc": "2022-10-10T15:26:40.1400738Z",
    "LastExecutionTime": "2022-10-10T00:09:00.0018481Z",
    "NextExecutionTime": "2022-10-11T00:09:00Z",
    "JobEnabled": true,
    "IsJobExecuting": false,
    "HeartbeatIntervalMs": 300000,
    "HostName": "me-xelus-deployment-5f488dfd46-hbsrx",
    "HostIpAddress": "10.128.4.161",
    "HealthStatus": {
      "ServiceStartupTime": "2022-10-08T17:26:30.4272489Z",
      "Validated": false,
      "Files": null,
      "FileCount": 0,
      "FileSharePath": null,
      "SoftwareMgrStatus": null,
      "ErrorMessage": null,
      "NextExecutionTime": "2022-10-11T00:09:00Z",
      "LastExecutionTime": "2022-10-10T00:09:00.0018481Z"
    }
  },
  {
    "JobName": "XelusTraxOrderFeedJob",
    "JobDescription": "XelusTraxOrderFeedJob is a scheduled job that feeds Xelus orders into Trax. The job is executed every 15 minutes.",
    "InstanceId": "98ce7f52-5bdd-4a45-aef3-22f835c136e0",
    "TimeStampUtc": "2022-10-10T15:26:40.1428341Z",
    "LastExecutionTime": "2022-10-10T15:15:18.4594798Z",
    "NextExecutionTime": "2022-10-10T15:30:19.7331035Z",
    "JobEnabled": true,
    "IsJobExecuting": false,
    "HeartbeatIntervalMs": 300000,
    "HostName": "me-xelus-deployment-5f488dfd46-hbsrx",
    "HostIpAddress": "10.128.4.161",
    "HealthStatus": {
      "ServiceStartupTime": "2022-10-08T17:26:30.4298368Z",
      "Validated": false,
      "Files": null,
      "FileCount": 0,
      "FileSharePath": null,
      "SoftwareMgrStatus": null,
      "ErrorMessage": null,
      "NextExecutionTime": "2022-10-10T15:30:19.7331035Z",
      "LastExecutionTime": "2022-10-10T15:15:18.4594798Z"
    }
  },
  {
    "JobName": "AirxpertFlightDataFeedJob",
    "JobDescription": "AzirxpertFlightDataFeedJob is a scheduled job that feeds QX Flight Data inforamtion to AirxPert. The job is executed every 60 secs but can be configured with a higher/lower interval setting.",
    "InstanceId": "f81a4992-9af5-46f8-b91a-7a2dcb9aba7f",
    "TimeStampUtc": "2022-10-10T15:22:24.3038963Z",
    "LastExecutionTime": "2022-10-10T15:09:49.4525847Z",
    "NextExecutionTime": "2022-10-10T15:24:52.3789262Z",
    "JobEnabled": true,
    "IsJobExecuting": false,
    "HeartbeatIntervalMs": 300000,
    "HostName": "me-airexpert-deployment-68bb64d79b-pz5mz",
    "HostIpAddress": "10.128.4.162",
    "HealthStatus": {
      "ServiceStartupTime": "2022-10-08T17:47:23.4261645Z",
      "NextExecutionTime": "2022-10-10T15:24:52.3789262Z",
      "LastExecutionTime": "2022-10-10T15:09:49.4525847Z",
      "RetryAttempts": 1,
      "HeartBeatIntervalMs": 300000,
      "JobIntervalMs": 900000,
      "HttpFailuresSinceStartup": 0
    }
  },
  {
    "JobName": "BusinessContinuityService",
    "JobDescription": "BusinessContinuityService is a scheduled job that runs once a day and exports all Tail software info as PDF files to an on-prem fileshare as part of a DisasterRecover protocol..",
    "InstanceId": "28843d7a-aeb7-4458-8ac0-39ad3d054843",
    "TimeStampUtc": "2022-10-10T15:22:50.2258523Z",
    "LastExecutionTime": "2022-10-10T13:00:00.0020964Z",
    "NextExecutionTime": "2022-10-11T13:00:00Z",
    "JobEnabled": true,
    "IsJobExecuting": false,
    "HeartbeatIntervalMs": 300000,
    "HostName": "me-mejobscheduler-deployment-7544bdb949-j9c6f",
    "HostIpAddress": "10.128.4.163",
    "HealthStatus": {
      "ServiceStartupTime": "2022-10-08T17:54:22.3159922Z",
      "Validated": true,
      "FileCount": 289,
      "FileSharePath": "TEMP\\Maintenance\\ASCT",
      "SoftwareMgrStatus": "ASCT Database status: True\nEng Eff Database status: True\n",
      "ErrorMessage": null
    }
  },
  {
    "JobName": "GEDataFeedsJob",
    "JobDescription": "GEDatFeedsJob is a scheduled job that runs once a day and retrieves AS & VX TRAX Aircraft Engine information and uploads the information in CSV format to an SFTP site provided by partner vendor.",
    "InstanceId": "48fc4a2d-9f67-4ceb-b493-0cc16b3a2a05",
    "TimeStampUtc": "2022-10-10T15:25:35.0661372Z",
    "LastExecutionTime": "2022-10-10T14:00:00.0005386Z",
    "NextExecutionTime": "2022-10-11T14:00:00Z",
    "JobEnabled": true,
    "IsJobExecuting": false,
    "HeartbeatIntervalMs": 300000,
    "HostName": "me-mejobscheduler-deployment-7544bdb949-j9c6f",
    "HostIpAddress": "10.128.4.163",
    "HealthStatus": {
      "ServiceStartupTime": "2022-10-08T17:54:22Z",
      "SftpServer": "adc-sftp-staging.geaviation.com:22",
      "SftpConnectionStatus": "Successful",
      "VxTraxServer": "HostName=sc9testraxdb01;Service=VXTXTST",
      "VxTraxConnectionStatus": "Successful",
      "AxTraxServer": "HostName=seatsatx19ora01;Service=TXASTEST",
      "AxTraxConnectionStatus": "Successful"
    }
  }
]