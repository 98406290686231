import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";


import Callback from "../components/auth/Callback";
import Logout from "../components/auth/Logout";
import LogoutCallback from "../components/auth/LogoutCallback";

import SilentRenew from "../components/auth/SilentRenew";
import NavBar from "../components/navBar/NavBar";
import GPMPage from "./pages/gpmPage/GPMPage";
import HomePage from "./pages/homePage/HomePage";

//import PublicPage from "../components/publicPage/publicPage";
import PingAuthRoute from "./PingAuthRoute";

export const AppRoutes = (
    <>
        <NavBar/>
        <Routes>
            <Route path="/" element={<PingAuthRoute component={HomePage} path={undefined} />} />
            <Route path="/signin-oidc" element={<Callback/>} />
            <Route path="/logout" element={<Logout/>} />
            <Route path="/logout/callback" element={<LogoutCallback/>} />
            <Route path="/silentrenew" element={<SilentRenew/>} />
            <Route path="*" element={<Navigate to="/" replace />}/>
            <Route path="/gpm" element={<GPMPage/>}/>
        </Routes>
    </>
    );
