import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import './navBar.css';

  
interface NavBarProps {
}

function NavBar(props: NavBarProps) {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
      }
    const closeMenu = () => {
        setNavbarOpen(false)
      }    
    return (
        <>
            <header className="header">
                <h3>M&E Job Monitor - Version</h3>
                <div className="top">
                    <div className="menu_icon" onClick={handleToggle}><FontAwesomeIcon icon="bars" color="white" size="xl"></FontAwesomeIcon></div>
                </div>
            </header>
            <nav className={`menu ${navbarOpen ? "menu_show" : ""}`}>
                <Link to="/" className="item_menu" onClick={closeMenu}>Jobs List</Link>
                <Link to="/" className="item_menu" onClick={closeMenu}>about</Link>
            </nav>        
        </>
      )}

export default NavBar;