interface JobEventsPanelProps {
    jobEvents: string[],
//   jobEvent: any;
}


function JobEventsPanel({jobEvents/*, jobEvent*/}: JobEventsPanelProps) {

    const items = jobEvents.map(s => (
        <div className="flex-container-row">{s}</div>
    ));
    return (
        <div>
            {items}

        </div>
    );
}

export default JobEventsPanel;

